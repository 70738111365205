<template>
    <v-row justify="center">
        <div v-if="dialog && appModule">
            <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar class="fixed-bar">
                        <v-btn icon @click="$emit('onCloseDialog')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ !appModule._id ? $t('title.create_module') : $t('title.update_module')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn :disabled="loading"
                                   text
                                   @click="validate">
                                {{ !appModule._id ? $t('button.create') : $t('button.update') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <Loading :visible="loading" />
                    <v-card-text>
                        <v-container>
                            <v-form ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.name"
                                                      :rules="rules.name"
                                                      :label="$t('input.module_name')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.desc"
                                                      :rules="rules.descr"
                                                      :label="$t('input.description')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.front_player_link"
                                                      :rules="rules.front_player_link"
                                                      :label="$t('input.front_player_link')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.api_player_link"
                                                      :rules="rules.api_player_link"
                                                      :label="$t('input.api_player_link')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.api_admin_link"
                                                      :rules="rules.api_admin_link"
                                                      :label="$t('input.api_admin_link')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.background_icon"
                                                      :label="$t('input.background_icon')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.app_id"
                                                      :rules="rules.app_id"
                                                      :label="$t('input.app_id')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.small_icon"
                                                     
                                                      :label="$t('input.small_icon')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="appModule.large_icon"
                                                      :label="$t('input.large_icon')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                    <v-combobox
                                        v-model="appModule.parent_id"
                                        :items="optionModule"
                                        :label="$t('input.parent')"
                                        item-text="name"
                                        item-value="_id"
                                        chips
                                        hide-details="auto"
                                    >
                                        <template v-slot:selection="data">
                                        <v-chip
                                            v-bind="data.attrs"
                                            :input-value="data.selected"
                                            :disabled="data.disabled"
                                        >
                                            {{ data.item._id ? displayMenu(data.item._id) : displayMenu(data.item) }}
                                        </v-chip>
                                        </template>
                                    </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row style="justify-content: right;" class="all_checkbox">
                                        <v-checkbox
                                        v-model="appModule.is_reverse_result"
                                        color="orange"
                                        class="reverse_result"
                                        :label="$t('input.reverse_result')"
                                        ></v-checkbox>

                                        <v-checkbox
                                        v-model="appModule.is_enable"
                                        color="orange"
                                        class="is_enable"
                                        :label="$t('input.enable')"
                                        ></v-checkbox>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-row>
</template>

<script>
    export default {
        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            darkmode: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            optionModule: {
                type: Array,
                default: () => {
                    return []
                }
            },
            rules: {
                type: Object,
                default: function () {
                    return {
                        name: [
                            v => !!v || 'Name is required',
                        ],
                        desc: [
                            v => !!v || 'description is required',
                        ],
                        front_player_link: [
                            v => !!v || 'Front Player link is required',
                        ],
                        api_player_link: [
                            v => !!v || 'API Player link is required',
                        ],
                        api_admin_link: [
                            v => !!v || 'API admin link is required',
                        ],
                        app_id: [
                            v => !!v || 'app_id is required',
                        ],
                    }
                }
            },
            isValidate: {
                type: Boolean,
                default: true
            },
            appModule: {
                type: Object,
                default: function () {
                    return {
                        name: null,
                        desc: null,
                        is_enable: null,
                        is_reverse_result: null,
                        front_player_link: null,
                        api_player_link: null,
                        api_admin_link: null,
                        background_icon: null,
                        app_id: null,
                        parent_id: null,
                        small_icon: null,
                        large_icon: null,
                        order: null
                    }
                }
            }
        },
        computed: {
            valid: {
                // getter
                get: function () {
                    return this.isValidate
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:isValidate', newValue)
                }
            }
        },
        watch: {
            optionModule: function() {},
        },
        methods: {
           
            validate() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit')
                }
            },
            displayMenu(_id) {
                console.log("this.optionModule",this.optionModule);
                return this.optionModule.find(item => item._id === _id).name
            }
        }
    };
</script>

<style lang="scss" scoped>
    .all_checkbox .reverse_result {
     padding-right: 10px;
     padding-left: 10px;
    }
    .fixed-bar {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
    top: 0em;
    z-index: 2;
    }
</style>
